<template>
	<v-container>
		<v-row class="justify-center">
			<v-col cols="12">
				<div class="headline mb-1 text-center">
					CONTACT US
				</div>
			</v-col>
		</v-row>
		<v-row class="justify-center">
			<v-col
				cols="12"
				md="4"
			>
				<v-card 
					class="rounded-lg" 
					style="background: #384154;"
					:loading="model_loading_"
				>
					<v-card-title class="white--text">Send us a message</v-card-title>

					<v-card-text>
						<v-row dense>
							<v-col>
								<v-text-field
									v-model="resourceData_.name"
									label="Name"
									solo
									dense
									class="rounded-lg"
									:error-messages="errors.name"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col>
								<v-text-field
									v-model="resourceData_.contact_number"
									label="Contact Number"
									solo
									dense
									class="rounded-lg"
									:error-messages="errors.contact_number"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col>
								<v-text-field
									v-model="resourceData_.email"
									label="Email"
									solo
									dense
									class="rounded-lg"
									:error-messages="errors.email"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col>
								<v-text-field
									v-model="resourceData_.company_country"
									label="Company & Country"
									solo
									dense
									class="rounded-lg"
									:error-messages="errors.company_country"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col>
								<v-textarea
									v-model="resourceData_.message"
									label="Message"
									solo
									dense
									class="rounded-lg"
									:error-messages="errors.message"
								></v-textarea>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-btn
									block
									depressed
									color="primary"
									class="text-capitalize secondary--text radius-10 mb-3"
									@click="submitMessage()"
								>
									Send Message
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col
				class="ml-md-15"
				cols="12"
				md="4"
			>
				<v-row>
					<v-col>
						<h3>Regional Office</h3>
					</v-col>
				</v-row>
				<v-row 
					v-for="(office) in resourceDataSet_" 
					:key="office.id"
				>
					<v-col>
						<h2>{{ office.location }}</h2>
						<h3>{{ office.company }}</h3>
						<p style="white-space: pre;">{{ office.address | replace('{/n}','\n') }}</p>
						<p> <v-icon>mdi-phone</v-icon> {{ office.contact_no }}</p>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { resourceMixin } from "@/mixins/ResourceMixin"
import { errorHandlerMixin } from "@/mixins/ErrorHandlerMixin"

const DEFAULT_IMAGE_FILE_TYPE_ID = 11;

export default {
	components:{
	},
	filters: {
		replace: function (st, rep, repWith) {
			const result = st.split(rep).join(repWith)
			return result;
		}
	},
	mixins: [
		resourceMixin,
		errorHandlerMixin,
	],
	data(){
		return {
			carouselNum: 0,
			errors: {},
		}
	},
	computed: {
		promotionPics()
		{
			return this.resourceDataSet_.filter(i => i.image)
		}
	},
	created(){
		this.initialize_()
	},
	methods:{
		getResourceData_(page = 1){
			let payload = {
				itemsPerPage: 99,
				is_archived: 0,
			}
			this.model_loading_ = true
			this.$api.getRegionalOfficeList(payload).then((res)=>{
				let { data, } = res.data.result
				this.resourceDataSet_ = [ ...this.resourceDataSet_, ...data ]
			}).catch((err)=>{
				this.errorHandler_(err)
			}).finally(()=>{
				this.model_loading_ = false
			})
		},
		submitMessage()
		{
			this.errors = {}
			let payload = {
				name: this.resourceData_.name,
				contact_number: this.resourceData_.contact_number,
				email: this.resourceData_.email,
				company_country: this.resourceData_.company_country,
				message: this.resourceData_.message,
			}

			this.model_loading_ = true
			this.$api.submitContactUs(payload).then((res)=>{
				this.$toast.success(res.data.message)
			}).catch((err)=>{
				this.errors = this.errorHandler_(err, ['name', 'contact_number', 'email', 'company_country', 'message'])
			}).finally(()=>{
				this.model_loading_ = false
			})
		},
	},
}
</script>
<style>
.rounded-card{
    border-radius:50px;
}
</style>
